.calendar-container {
  border: 1px solid var(--mantine-color-gray-3);
  width: 100%;
  max-height: calc(100vh - 146px);
}

.calendar-container thead th {
  border: 1px solid var(--mantine-color-gray-3);
}

.calendar-month-level {
  width: 100%;
}

.calendar-header {
  width: 100%;
  max-width: none;
  margin: 0;
  min-height: 4rem;
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid var(--mantine-color-gray-3);
  border-right: 1px solid var(--mantine-color-gray-3);
  border-left: 1px solid var(--mantine-color-gray-3);
  background: var(--mantine-color-gray-0);
}

.calendar-header-control {
  min-height: 3.75rem;
  min-width: 3.5rem;
}

.calendar-container tbody td {
  border: 1px solid var(--mantine-color-gray-3);
  vertical-align: top;
}

.calendar-day {
  width: 100%;
  padding: 16px;
  height: 100%;
  min-height: 10.2rem;
  align-items: start;
  &[data-outside] {
    background-color: var(--mantine-color-gray-0);
    border: none;
  }
  &[data-hidden] {
    display: flex;
    font-size: 0;
  }
}

.calendar-weekday {
  height: 50px;
}

.calendar-header-level {
  font-size: 20px;
  height: 3.75rem;
  font-weight: 400;
}
