.container {
  margin-top: 8px;
  border: 1px solid var(--mantine-color-gray-4);
  border-radius: 4px;
  padding: 24px;
  position: relative;
}

.label {
  position: absolute;
  top: -6px;
  margin-left: -12px;
  padding: 0 8px;
  background-color: var(--mantine-color-white);
}
