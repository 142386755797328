.table-header {
  position: sticky;
  height: 32px;
  background-color: var(--mantine-color-gray-1);
  border-radius: 8px;
}

.table-header th {
  position: sticky;
  top: 0;
  background-color: var(--mantine-color-gray-1);
}

.table {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 4px;
  overflow: auto;
}
