.label-box {
  display: flex;
  cursor: default;
  height: 28px;
  align-items: center;
  align-items: "center";
  background-color: var(--mantine-color-gray-1);
  padding-left: 8px;
  border-radius: 4px;
}

.close-button {
  color: var(--mantine-color-gray-7);
  margin-left: 8px;
  margin-right: 8px;
}
