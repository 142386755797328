.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.inactive {
  background-color: var(--mantine-color-gray-5);
}
.active {
  background-color: var(--mantine-color-green-6);
}
