.flex-content {
  border-radius: 4px;
}

.tr-content td:nth-child(1) {
  width: 4%;
}

.tr-content td:nth-child(2) {
  width: 10%;
}

.tr-content td:nth-child(3) {
  width: 60%;
}

.tr-content td:nth-child(5) {
  width: 3%;
}
