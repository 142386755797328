.map-container {
  position: relative;
  cursor: pointer;
  height: 148px;
  width: 100%;
}

.map-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
