.bordered-container {
  border-radius: 4px;
  padding: 24px;
  border: 1px solid var(--mantine-color-gray-3);
  overflow: hidden;
}
.label {
  font-weight: 600;
  margin-bottom: 4px;
}
.disabled {
  background-color: var(--mantine-color-gray-1);
  color: var(--mantine-color-gray-6);
}
