.scroll-area {
  height: calc(100vh - 101px);
  z-index: 0;
  border-bottom: 1px solid var(--mantine-color-gray-3);
  overflow-y: scroll;
}

.thead {
  background-color: var(--mantine-color-gray-1);
}

.td-empty {
  height: calc(100vh - 153px);
  &:hover {
    background-color: #fcfcfcff;
  }
}
