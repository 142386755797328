.tbody-tr {
  height: 64px;
}
.tbody-td {
  text-align: center;
}

.tbody-left-td {
  text-align: start;
  padding-left: 16px !important;
}

.tbody-right-td {
  text-align: center;
}
