.accordion-control {
  padding-left: 0;
  padding-right: 0;
  border-bottom: solid 1px var(--mantine-color-gray-3);
}
.accordion-content {
  padding: 0;
  color: var(--mantine-color-gray-9);
}
.accordion-bordered {
  border-bottom: solid 1px var(--mantine-color-gray-3);
  padding-bottom: 1rem;
}
