.sc-label {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sc-indicator {
  height: 24px;
}
.right-section {
  color: var(--mantine-color-gray-6);
  margin-right: 4px;
}
.number-input {
  flex: 1 1 120px;
}
