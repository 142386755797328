.asterisk {
  font-size: 16px;
}
.description {
  margin-top: 4px;
  margin-bottom: 16px;
}
.employee-alert-icon {
  position: absolute;
  left: -32px;
  bottom: 6px;
}
.sc-indicator {
  height: 24px;
}
.sc-label {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
