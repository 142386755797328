.input-wrapper-label {
  display: flex;
  gap: 2;
}

.stack-content {
  padding: 0px 16px 16px 16px;
}

.dropzone {
  display: none;
}

.dropzone-content:hover > .dropzone {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
