.reason-text {
  color: var(--mantine-color-gray-9);
}

.amount-text {
  color: var(--mantine-color-gray-9);
  font-weight: 600;
}
.alert-wrapper {
  border: 1px solid var(--mantine-color-red-2);
}
