.payroll-thead {
  position: sticky;
  height: 32px;
  background-color: var(--mantine-color-gray-1);
  border-radius: 8px;
}

.payroll-thead th {
  position: sticky;
  top: 0;
  background-color: var(--mantine-color-gray-1);
}

.payroll-table {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 4px;
  overflow: auto;
}

/* Row classNames */
.tbody-tr {
  height: 48px;
}

.td-tag {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
