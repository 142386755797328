.flex-menu {
  cursor: pointer;
}

.details-tr {
  height: 48px;
  background-color: var(--mantine-color-gray-0);
}

.tbody-td {
  text-align: center;
}

.tbody-left-td {
  text-align: start;
  padding-left: 16px !important;
}

.tbody-right-td {
  text-align: center;
  padding-right: 16px !important;
}
