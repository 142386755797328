.summary {
  border: 1px solid var(--mantine-color-gray-2);
  border-top: 0;
}

.summary:nth-child(1) {
  border-top: 1px solid var(--mantine-color-gray-2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.summary:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: 1px solid var(--mantine-color-gray-2);
}
