.modal-inner {
  justify-content: flex-end;
  padding-right: 52px;
}

.modal-header {
  display: none;
}

.modal-body {
  padding: 0;
}

.stack-content {
  border-right: 1px solid var(--mantine-color-gray-3);
}

.date-picker-button {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
}

.date-picker-day[data-weekend] {
  color: unset;
}

.date-picker-day[data-selected] {
  color: var(--mantine-color-white);
}

.date-picker-lifetime {
  background-color: var(--mantine-color-primary-0);
  border-radius: 0;
  &:hover {
    background-color: var(--mantine-color-primary-1);
  }
}

.date-picker-lifetime[data-selected] {
  background-color: var(--mantine-color-primary-0) !important;
  color: var(--mantine-color-black) !important;
  &:hover {
    background-color: var(--mantine-color-primary-1) !important;
  }
}
